export const SET_FILLED = "SET_FILLED";
export const SET_TOKEN = "SET_TOKEN";

export const setFilled = (filled) => ({
  type: SET_FILLED,
  payload: filled,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});
import React, {useState, useEffect} from "react";
import routeInit from "./routes";

import { Redirect } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios-controller';
import { useDispatch, useSelector } from "react-redux";
import { setFilled, setToken } from "../../redux/actions";

const InitialPage = () => {

    const [telegramUserId, setTelegramUserId] = useState("622089839");

    const [isLoading, setIsLoading] = useState(true);

    const [acceptAccount, setAcceptAccount] = useState(false);

    const key = CryptoJS.enc.Hex.parse("f34f852aefd041203a567c7f7083688371b7bdac3a3b2fe981c1de2d89983993");
    const iv = CryptoJS.enc.Hex.parse("db5592c547ed2cc70621d4a1a8ed9ec2");

    const dispatch = useDispatch();

    const getState = useSelector((state) => state);

    useEffect(() => {

        const signInRequest = async (hash) => {
        try {
            const response = await axios.post("/api/auth/signin", {
                telegramID: hash
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });

            console.log(response.data);

            dispatch(setToken(response.data["token"]));
            dispatch(setFilled(response.data["user"]["filled"]));

            setAcceptAccount((response.data["user"]["filled"]));

            setIsLoading(false);

        } catch (error) {
            console.error('POST Err', error);
        }
        };

        const encryptText = async  () => {
        const encrypted = CryptoJS.AES.encrypt(telegramUserId, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

        await signInRequest(encrypted.toString());
        };

        encryptText();
    }, []);

    if (!isLoading) {
        if (acceptAccount === false) {
            return <Redirect to="/account" />
        } else {
            return <Redirect to="/deals" />
        }
    }

    return(
        <section className="init">
            <div className="container">
                <div className="init-block">
                    <div className="init-block__content">
                        <h1 className="init-block__title">
                            Юрик
                        </h1>
                        <p className="init-block__text">
                            Договаривайтесь легко
                        </p>
                    </div>
                    <div className="init-block__politic">
                        Всякая юридическая информация<br />
                        УНП 1983189129
                    </div>
                </div>
            </div>
        </section>
    )
};

export {routeInit};

export default InitialPage;
// reducer.js
import { combineReducers } from "redux";
import { SET_FILLED, SET_TOKEN } from "./actions";

const initialState = {
  getFilled: localStorage.getItem('getFilled') ? JSON.stringify(localStorage.getItem('getFilled')) : false,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null
};

const filledReducer = (state = initialState.getFilled, action) => {
  switch (action.type) {
    case SET_FILLED:
      localStorage.setItem("getFilled", JSON.stringify(action.payload));
      return {
        ...state,
        getFilled: action.payload,
      };
    default:
      return state;
  }
};

const tokenReducer = (state = initialState.token, action) => {
  switch (action.type) {
    case SET_TOKEN:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

// Combine Reducers
const rootReducer = combineReducers({
  getFilled: filledReducer,
  token: tokenReducer,
});

export default rootReducer;


// import { combineReducers } from "redux";
// import { SET_FILLED, SET_TOKEN } from "./actions";

// const initialState = {
//   getFilled: localStorage.getItem('getFilled') ? JSON.stringify(localStorage.getItem('getFilled')) : false,
//   token: localStorage.getItem('token') ? localStorage.getItem('token') : null
// };

// const filledReducer = (state = initialState.getFilled, action) => {
//   switch (action.type) {
//     case SET_FILLED:
//       localStorage.setItem("getFilled", JSON.stringify(action.payload));
//       return {
//         ...state,
//         getFilled: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// const tokenReducer = (state = initialState.token, action) => {
//   switch (action.type) {
//     case SET_TOKEN:
//       localStorage.setItem("token", action.payload);
//       return {
//         ...state,
//         token: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// // Combine Reducers
// const rootReducer = combineReducers({
//   getFilled: filledReducer,
//   token: tokenReducer,
// });

// export default rootReducer;

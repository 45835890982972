import React from "react";
import './styles.scss';
import { NavLink } from 'react-router-dom';

const BackNav = (props) => {

    let isPageCreate;

    if (props.stage && props.stage > 1) {
        isPageCreate = true;
    } else {
    }

    return (
        <div className="page-back f-center-jcsb">
            <div className="page-back__offer f-center">
                {isPageCreate ? (
                    <div onClick={() => props.handleClick(props.stage - 1)} className="page-back__link">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.98438 9.94922C3.98438 9.82552 4.00716 9.71159 4.05273 9.60742C4.09831 9.49674 4.16667 9.39583 4.25781 9.30469L12.002 1.72656C12.1777 1.55729 12.3893 1.47266 12.6367 1.47266C12.806 1.47266 12.9557 1.51172 13.0859 1.58984C13.2227 1.66797 13.3301 1.77539 13.4082 1.91211C13.4928 2.04232 13.5352 2.19206 13.5352 2.36133C13.5352 2.60221 13.4473 2.8138 13.2715 2.99609L6.16211 9.94922L13.2715 16.9023C13.4473 17.0846 13.5352 17.2962 13.5352 17.5371C13.5352 17.7064 13.4928 17.8561 13.4082 17.9863C13.3301 18.123 13.2227 18.2305 13.0859 18.3086C12.9557 18.3867 12.806 18.4258 12.6367 18.4258C12.3893 18.4258 12.1777 18.3379 12.002 18.1621L4.25781 10.5938C4.16667 10.5026 4.09831 10.4049 4.05273 10.3008C4.00716 10.1901 3.98438 10.0729 3.98438 9.94922Z" fill="#6D7780"/>
                        </svg>
                    </div>
                ) : (
                    <NavLink to={props.backPage} className="page-back__link">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.98438 9.94922C3.98438 9.82552 4.00716 9.71159 4.05273 9.60742C4.09831 9.49674 4.16667 9.39583 4.25781 9.30469L12.002 1.72656C12.1777 1.55729 12.3893 1.47266 12.6367 1.47266C12.806 1.47266 12.9557 1.51172 13.0859 1.58984C13.2227 1.66797 13.3301 1.77539 13.4082 1.91211C13.4928 2.04232 13.5352 2.19206 13.5352 2.36133C13.5352 2.60221 13.4473 2.8138 13.2715 2.99609L6.16211 9.94922L13.2715 16.9023C13.4473 17.0846 13.5352 17.2962 13.5352 17.5371C13.5352 17.7064 13.4928 17.8561 13.4082 17.9863C13.3301 18.123 13.2227 18.2305 13.0859 18.3086C12.9557 18.3867 12.806 18.4258 12.6367 18.4258C12.3893 18.4258 12.1777 18.3379 12.002 18.1621L4.25781 10.5938C4.16667 10.5026 4.09831 10.4049 4.05273 10.3008C4.00716 10.1901 3.98438 10.0729 3.98438 9.94922Z" fill="#6D7780"/>
                        </svg>
                    </NavLink>
                )}
                <p className="page-back__title">
                    {props.title}
                </p>
            </div>
            {props.handleEdit ? (
                <div className="page-back__edit" onClick={() => props.handleEdit()}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.41406 19.9238C3.39844 19.9238 2.63346 19.6699 2.11914 19.1621C1.60482 18.6608 1.34766 17.9056 1.34766 16.8965V5.64648C1.34766 4.63737 1.60482 3.88216 2.11914 3.38086C2.63346 2.87305 3.39844 2.61914 4.41406 2.61914H15.4785L13.9062 4.19141H4.44336C3.95508 4.19141 3.57747 4.32161 3.31055 4.58203C3.05013 4.84245 2.91992 5.22656 2.91992 5.73438V16.8184C2.91992 17.3262 3.05013 17.7103 3.31055 17.9707C3.57747 18.2246 3.95508 18.3516 4.44336 18.3516H15.8594C16.1979 18.3516 16.4844 18.2246 16.7188 17.9707C16.9596 17.7103 17.0801 17.3262 17.0801 16.8184V7.44336L18.6523 5.87109V16.8965C18.6523 17.9056 18.4082 18.6608 17.9199 19.1621C17.4382 19.6699 16.7578 19.9238 15.8789 19.9238H4.41406ZM7.96875 13.5957C7.8776 13.6348 7.79297 13.6152 7.71484 13.5371C7.64323 13.4525 7.62695 13.3678 7.66602 13.2832L8.54492 11.4473L17.6562 2.33594L18.9941 3.6543L9.87305 12.7656L7.96875 13.5957ZM19.7168 2.94141L18.3789 1.59375L19.0918 0.890625C19.2546 0.734375 19.4401 0.64974 19.6484 0.636719C19.8633 0.623698 20.0456 0.692057 20.1953 0.841797L20.4199 1.07617C20.5892 1.23893 20.6706 1.42773 20.6641 1.64258C20.6641 1.85091 20.5827 2.04297 20.4199 2.21875L19.7168 2.94141Z" fill="#0180FF"/>
                    </svg>
                </div>
            ) : '' }
        </div>
    )
}

export default BackNav;
import React from "react";
import './styles.scss';

const Loading = (props) => {
    return (
        <>
            {props.loading ? (
                    <div className="loading">
                        <div className="loading-block">
                            <div class="loader">
                                
                            </div>
                        </div>
                    </div>
            ) : ('')}
        </>
    )
}

export default Loading; 
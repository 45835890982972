import React from "react";

const DealWriting = () => {
    return (
        <div className="create-writing">
            <div className="create-writing__content">
                <h2>
                    Договор подряда на выполнение ремонтных работ
                </h2>
                <p>
                    <b>Заказчик:</b> [Ваше полное имя], далее по тексту "Заказчик", с одной стороны, и
                </p>
                <p>
                    <b>Исполнитель:</b>  Индивидуальный предприниматель Дмитриев [Имя Отчество], далее по тексту "Исполнитель", с другой стороны, заключили настоящий Договор о нижеследующем:
                </p>
                <ol>
                    <li>
                        <b>Предмет Договора</b> <br/>
                        Исполнитель обязуется по заданию Заказчика выполнить ремонт балкона (далее - Работы), а Заказчик обязуется принять и оплатить эти Работы.
                    </li>
                    <li>
                        <b>Обязанности Сторон</b> <br/>
                        2.1. Заказчик обязуется предоставить необходимые материалы для выполнения Работ и обеспечить доступ Исполнителя к месту проведения Работ.<br/>
                        2.2. Исполнитель обязуется выполнить Работы качественно и в срок, указанный в п. 3 настоящего Договора.
                    </li>
                    <li>
                        <b>Сроки выполнения Работ</b> <br/>
                        Работы должны быть выполнены в течение 1 (одного) месяца с момента подписания настоящего Договора.
                    </li>
                    <li>
                        <b>Оплата Работ</b> <br/>
                        Стоимость Работ определяется по соглашению Сторон и указывается в Акте выполненных работ, который подписывается Сторонами по завершении Работ.
                    </li>
                </ol>
                <p>
                    <b>Подписи Сторон:</b>
                </p>
                <p>
                    Заказчик: _______________________
                </p>
                <p>
                    Исполнитель: _____________________
                </p>
                <p>
                    Дата: ___________________________
                </p>
            </div>
            <div className="create-writing__work f-center-center">
                <p className="create-writing__work-title">
                    Юрик
                </p>
                <div className="create-writing__work-logo">
                    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="22.5" cy="22" r="5.995" stroke="#0180FF" stroke-width="0.01"/>
                        <circle cx="22.5" cy="22" r="11" stroke="#0180FF" stroke-width="2"/>
                        <circle cx="22.5" cy="22" r="17" stroke="#0180FF" stroke-width="2"/>
                    </svg>
                </div>
                <p className="create-writing__work-text">
                    Работаю
                </p>
            </div>
        </div>
    )
}

export default DealWriting;